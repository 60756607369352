import styled from 'styled-components'
const Container = styled.section`
  position: relative;
  pointer-events: none;
  z-index: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-content: space-around;
  flex-align: start;
  align-items: self-start;
  // width: 80%;
  max-width: 1440px;
  margin: 0 auto;
`

export default Container
