import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { devices } from '../theme'
import { graphql, useStaticQuery } from 'gatsby'

// const Img = styled.img`
//   max-width: 100%;
//   height: auto;
// `
const ImgContainer = styled.div`
  width: 100%;
  display: inline-block;
  overflow: hidden;
  pointer-events: auto;
  position: relative;
  padding: 0 32px;
  margin-bottom:64px;
  @media ${devices.tablet} {
    width: 50%;
  }
  @media ${devices.laptop} {
    width: 33.3%;
  }
  }
`
const MobileImg = ({ index, src, id, alt, fluid, ...rest }) => {
  return (
    <ImgContainer>
      <Img fluid={fluid} alt={alt} />
    </ImgContainer>
  )
}

export default MobileImg
