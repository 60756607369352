import React from 'react'
import { SplitLayoutContainer, SplitLayoutItem } from '../styled'
import Img from 'gatsby-image'
import ProjectLink from '../ProjectLink'
import styled from 'styled-components'
const Container = styled(SplitLayoutItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const BgImage = styled(Img)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute !important;
  height: 100%;
  filter: grayscale(100%) contrast(5);
  mix-blend-mode: screen;
  width: 100%;
  top: 0;
  left: 0;
`
const MenuItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export default function NextPrev({ next, prev }) {
  console.log({ next, prev })
  return (
    // <p>hey</p>
    <SplitLayoutContainer>
      <Container style={{ backgroundColor: prev.colours[0] }}>
        <BgImage fluid={prev.heroImage.fluid} />
        <MenuItemsContainer>
          <ProjectLink
            to={prev.slug}
            style={{ color: prev.colours[0] }}
            className="active"
          >
            {prev.name}
          </ProjectLink>
        </MenuItemsContainer>
      </Container>
      <Container style={{ backgroundColor: next.colours[0] }}>
        <BgImage fluid={next.heroImage.fluid} />
        <MenuItemsContainer>
          <ProjectLink
            to={next.slug}
            style={{ color: next.colours[0] }}
            className="active"
          >
            {next.name}
          </ProjectLink>
        </MenuItemsContainer>
      </Container>
    </SplitLayoutContainer>
  )
}
