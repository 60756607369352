import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
const desktopXYZ = [
  { x: 0, y: 0, z: 7, opacity: 0 },
  { x: 0, y: 0, z: -56, opacity: 0.15 },
]

const ImgFluid = styled(Img)`
  width: 100%;
  height: auto;
`
const ImgContainer = styled.div`
  width: 100%;
  overflow: hidden;
  pointer-events: auto;
  margin: 80px auto 0;
  padding: 0 64px;
  position: relative;
`
export default class DesktopImg extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }

  render() {
    const { index, src, fluid, alt, ...rest } = this.props
    const isEven = (index + 1) % 2 === 0
    const { x, y, z, opacity } = desktopXYZ[isEven ? 1 : 0]
    return (
      <ImgContainer y={y} z={z} x={x} opacity={opacity}>
        <ImgFluid fluid={fluid} alt={alt} />
      </ImgContainer>
    )
  }
}
