import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container } from '../components/styled'
import styled from 'styled-components'
const IntroContainer = styled(Container)`
  text-align: center;

  padding: 10rem 32px 64px;
  max-width: 1024px;
  text-align: left;
`

const Blurb = styled.div`
  margin-bottom: 32px;
  p {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 16px;
    text-align: left;
  }
`
const MetaRow = styled.div`
  display: flex;
  padding: 0 0 16px;
  min-width: 75%;
`
const MetaItem = styled.div`
  text-align: left;
  margin-right: 32px;
  h4 {
    font-family: 'Sansita', sans-serif;
    font-weight: 700;
  }
  p,
  h4 {
    // display: inline-block;
    margin-bottom: 16px;
    margin-top: 0;
    font-size: 18px;
  }
  p {
    // margin-left: 16px;
  }
`

class ProjectIntro extends Component {
  render() {
    const { name, roles, tech, year, workingAt, blurbHtml } = this.props
    return (
      <IntroContainer>
        <MetaItem>
          <p>{year}</p>
        </MetaItem>
        <h2 style={{ marginTop: 0, marginBottom: '16px' }}>{name}</h2>
        <Blurb dangerouslySetInnerHTML={{ __html: blurbHtml }} />
        <div style={{ display: 'flex' }}>
          <MetaItem>
            <h4>Roles</h4>
            <p>{roles}</p>
          </MetaItem>
          <MetaItem>
            <h4>Tech</h4>
            <p>{tech}</p>
          </MetaItem>
          <MetaItem>
            <h4>Working @</h4>
            <p>{workingAt}</p>
          </MetaItem>
        </div>
      </IntroContainer>
    )
  }
}

ProjectIntro.propTypes = {
  name: PropTypes.string.isRequired,
  roles: PropTypes.string.isRequired,
  tech: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  workingAt: PropTypes.string.isRequired,
  blurbHtml: PropTypes.string.isRequired,
}

export default ProjectIntro
