import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
const Img = styled.img`
  width: 100%;
  height: auto;
`
const ImgContainer = styled.div`
  width: 100%;
  display: block;
  overflow: hidden;
  pointer-events: auto;
  position: relative;
  margin: 10rem auto;
`
export default class FullWidthImg extends Component {
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }

  render() {
    const { src, alt, ...rest } = this.props
    return (
      <ImgContainer>
        <Img src={src} alt={alt} {...rest} />
      </ImgContainer>
    )
  }
}
