import React, { Component } from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import styled from 'styled-components'
import ProjectIntro from '../components/ProjectIntro'
import MobileImg from '../components/MobileImg'
import DesktopImg from '../components/DesktopImg'
import FullWidthImg from '../components/FullWidthImg'
import ImgSectionContainer from '../components/ImgSectionContainer'
import ReactResizeDetector from 'react-resize-detector'
import { ColourGradient } from '../components/styled'
import NextPrev from '../components/NextPrev'
const ContentWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  top: 0;
  transform-style: preserve-3d;
`

const ParallaxOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  perspective: 300px;
  perspective-origin: 50%;
  z-index: 1;
  overflow-y: scroll;
`
const ParallaxPageContainer = styled.div`
  height: 100%;
`

class project extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollHeight: '80%',
    }
    this.scrollContainer = React.createRef()
  }
  setScrollHeight = title => {
    if (this.scrollContainer && this.scrollContainer.current) {
      const scrollHeight =
        (this.scrollContainer.current.scrollHeight / 100) * 95
      this.setState({ scrollHeight })
    } else {
      return
    }
  }

  render() {
    const {
      roles,
      year,
      name,
      tech,
      workingAt,
      colours,
      heroImage,
      desktopImages,
      mobileImages,
      about: {
        childMarkdownRemark: { html },
      },
      next,
      prev,
    } = this.props.pageContext
    const { scrollHeight } = this.state

    return (
      <Layout>
        <Header />

        <ProjectIntro
          name={name}
          roles={roles}
          tech={tech}
          year={year}
          workingAt={workingAt}
          blurbHtml={html}
        />
        <div style={{ backgroundColor: colours[0], padding: '10rem 0' }}>
          {/* {heroImage && (
            <FullWidthImg
              src={heroImage.file.url}
              alt={heroImage.title || heroImage.file.url}
              onLoad={title => this.setScrollHeight(title)}
            />
          )} */}
          {mobileImages && (
            <ImgSectionContainer>
              {mobileImages.map(({ fluid, file: { url }, title, id }, i) => (
                <MobileImg
                  src={url}
                  alt={title || url}
                  index={i}
                  fluid={fluid}
                  key={id}
                  onLoad={title => this.setScrollHeight(title)}
                />
              ))}
            </ImgSectionContainer>
          )}
          {desktopImages && (
            <ImgSectionContainer>
              {desktopImages.map(({ fluid, file: { url }, title, id }, i) => (
                <DesktopImg
                  src={url}
                  fluid={fluid}
                  alt={title || url}
                  index={i}
                  key={id}
                  onLoad={title => this.setScrollHeight(title)}
                />
              ))}
            </ImgSectionContainer>
          )}
        </div>
        <NextPrev next={next} prev={prev} />
      </Layout>
    )
  }
}

// project.propTypes = {}

export default project
